import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
//import PageHeader from '../components/PageHeader';
//import { UserListHeader, UserListItem, UserItemModal } from '../components/user/index';
import { StyledButton, CheckBox, Spinner, Input, Icon } from '../../components/UI';
import { fetchPartners } from '../../store/partnerSlice';
import './PartnersPage.modules.css';

const PartnersPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { list, isLoading, error } = useSelector(state => state.partners);
    const [selectedItem, setSelectedItem] = useState(null);
    //const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        dispatch(fetchPartners());
    }, []);

    const onSelectHandler = (item) => {
        if (selectedItem && selectedItem.id === item.id) {
            setSelectedItem(null);
        } else {
            setSelectedItem(item);
        };
    };

    const onEditHandler = (item) => {
        navigate(`/partners/${item.id}`, item);
    };

    return (
        <>
            <PageHeader
                name="Партнеры"
            >
                {selectedItem && <StyledButton name="Редактровать" onClickHandler={() => onEditHandler(selectedItem)} />}
            </PageHeader>
            <div className='banks-container'>
                <div className='banks-table'>
                    {isLoading && <Spinner />}
                    <div className="partners-table-row header">
                        <div className="id">ИД</div>
                        <div className="fio">ФИО</div>
                        <div className="phone">Номер телефона</div>
                        <div className="email">E-mail</div>
                        {/*<div className="referal-count">{item.email}</div>*/}
                    </div>
                    {list.length > 0 ?
                        <>
                            {list && list.map(item => {
                                return (
                                    <div
                                        onClick={() => onSelectHandler(item)}
                                        onDoubleClick={() => onEditHandler(item)}
                                        className={"partners-table-row " + (selectedItem && selectedItem.id === item.id ? "selected" : "")}
                                        key={item.id}
                                        style={{ display: 'flex', flexDirection: "row", gap: 8 }}
                                    >
                                        <div className="id">{item.id}</div>
                                        <div className="fio">{item.firstName} {item.lastName}</div>
                                        <div className="phone">{item.phone}</div>
                                        <div className="email">{item.email}</div>
                                        {/*<div className="referal-count">{item.email}</div>*/}
                                    </div>
                                )
                            })}
                        </>
                        :
                        <div className='no-items'>Список пока пуст.</div>
                    }
                </div>
            </div>
        </>

    )
}

export default PartnersPage;
