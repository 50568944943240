import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { checkAuth } from './authSlice';

export const fetchPartners = createAsyncThunk(
    'partners/fetchPartners',
    async function (_, { rejectWithValue, getState, dispatch }) {
        try {
            const { user } = getState().auth;

            await dispatch(checkAuth(user));

            const response = await fetch('/api/ppa', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.accessToken}`,
                },
            });

            if (!response.ok) {
                if (response.status === 401) {
                    throw new Error('Unathorized!');
                } else
                    throw new Error('Server Error!');
            }

            const data = await response.json();

            return data;
        } catch (error) {
            console.log("ERROR", error);
            return rejectWithValue(error.message);
        }
    }
);

export const fetchTransactions = createAsyncThunk(
    'partners/fetchTransactions',
    async function (partnerId, { rejectWithValue, getState, dispatch }) {
        try {
            const { user } = getState().auth;

            await dispatch(checkAuth(user));

            const response = await fetch(`/api/ppa/transactions/${partnerId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.accessToken}`,
                },
            });

            if (!response.ok) {
                if (response.status === 401) {
                    throw new Error('Unathorized!');
                } else
                    throw new Error('Server Error!');
            }

            const data = await response.json();

            return data;
        } catch (error) {
            console.log("ERROR", error);
            return rejectWithValue(error.message);
        }
    }
);

//
export const acceptPayment = createAsyncThunk(
    'partners/acceptPayment',
    async function (data, { rejectWithValue, getState, dispatch }) {
        try {
            const { user } = getState().auth;

            await dispatch(checkAuth(user));

            const response = await fetch(`/api/ppa/accept-pay-cash/${data.referalGuid}/${data.transactionId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.accessToken}`,
                },
            });

            if (!response.ok) {
                if (response.status === 401) {
                    throw new Error('Unathorized!');
                } else
                    throw new Error('Server Error!');
            }

            //const data = await response.json();

            return;
        } catch (error) {
            console.log("ERROR", error);
            return rejectWithValue(error.message);
        }
    }
);

export const partnerSlice = createSlice({
    name: 'partners',
    initialState: {
        list: [],
        transactions: [],
        isLoading: false,
        error: null
    },
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPartners.fulfilled, (state, action) => {
                //console.log(action.payload);
                state.list = action.payload;
            })
            .addCase(fetchTransactions.fulfilled, (state, action) => {
                state.transactions = action.payload;
            })
            .addCase(acceptPayment.fulfilled, (state, action) => {

            })
            //.addCase(updateUser.fulfilled, (state, action) => {
            //    const editedUser = state.list.find(user => user.id === action.payload.id);
            //    if (editedUser) {
            //        state.list = state.list.map(user => {
            //            if (user.id === action.payload.id)
            //                return action.payload;
            //            else
            //                return user;
            //        });
            //    };
            //})
            //.addCase(deleteUser.fulfilled, (state, action) => {
            //    state.list = state.list.filter(user => user.id !== action.payload);
            //})
            .addMatcher(isError, (state, action) => {
                state.error = action.payload;
                state.isLoading = false;
            })
            .addMatcher(isPending, (state) => {
                state.error = null;
                state.isLoading = true;
            })
            .addMatcher(isFulfilled, (state) => {
                state.isLoading = false;
            });
    }
})


// Action creators are generated for each case reducer function
export const { } = partnerSlice.actions

export default partnerSlice.reducer

function isError(action) {
    return action.type.endsWith('rejected') && action.type.startsWith('partners');
};

function isPending(action) {
    return action.type.endsWith('pending') && action.type.startsWith('partners');
};

function isFulfilled(action) {
    return action.type.endsWith('fulfilled') && action.type.startsWith('partners');
};