import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import { Spinner } from '../../components/UI';
//import MyFriends from '../tg/MyFriends';
import { useNavigate, useParams } from 'react-router-dom';
import { acceptPayment, fetchTransactions, fetchPartners } from '../../store/partnerSlice';
import { StyledButton } from '../../components/UI/index';

const clientPages = [
    {
        id: 1,
        name: "Общая информация",
    },
    {
        id: 2,
        name: "Транзакции",
    },
    {
        id: 3,
        name: "Дополнительно"
    },
];

const SubPageComponent = ({ pageId, data = null }) => {
    //return <></>
    switch (pageId) {
        case 1:
            return <ReferalInfoPage />
        case 2:
            return <ReferalTransactionsPage />
        default:
            return <>{pageId}</>
    };
};

const ReferalInfoPage = () => {
    const { id } = useParams();
    const partner = useSelector(state => state.partners.list.find(x => x.id == id));
    useEffect(() => {
        console.log("PARTNER INFO", partner);

    }, [partner])

    if (!partner) return null;

    return (
        <>
            <div>{partner.id}</div>
            <div>{partner.firstName}</div>
            <div>{partner.lastName}</div>
            <div>{partner.phone}</div>
            <div>{partner.email}</div>
            <div>{partner.referalGuid}</div>
        </>
    )    
}

const ReferalTransactionsPage = () => {
    const transactionTypes = [
        'Начисление',
        'Вывод',
        'Запрос на вывод',
    ]

    const { id } = useParams();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { transactions } = useSelector(state => state.partners);
    const partner = useSelector(state => state.partners.list.find(x => x.id == id));

    useEffect(() => {
        if(id)
            dispatch(fetchTransactions(id));
    }, [id]);

    const acceptPaymentHandler = (data) => {
        console.log("pay button data: ", data);
        dispatch(acceptPayment(data))
            .finally(() => {
                dispatch(fetchTransactions(id))
            })
    }

    return (
        <>
            {transactions.map(item => {
                return (
                    <div key={item.id} style={{ display: "flex", flexDirection: "row", gap: 24 }}>
                        <div>{item.id}</div>
                        <div>{new Date(item.transactionDate).toLocaleString('ru-RU')}</div>
                        <div>{transactionTypes[item.direction]}</div>
                        <div>{item.summ}</div>
                        <div>Остаток: {item.balance}</div>
                        <div>
                            {!item.transactionConfirmed && item.direction == 2 &&
                                <StyledButton
                                    name="Подтвердить оплату"
                                onClickHandler={() => acceptPaymentHandler({ referalGuid: partner.referalGuid, transactionId: item.id }) }
                                />
                            }
                            {item.transactionConfirmed && item.direction == 2 && <>Выплачено</> }
                        </div>
                    </div>
                )
            })}
        </>
    )
}

const PartnerPage = () => {
    //const { id } = useParams();
    const dispatch = useDispatch();
    //const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        dispatch(fetchPartners());
    }, []);
    const [currentPageId, setCurrenPageId] = useState(clientPages[0].id);

    return (
        <>
            <PageHeader
                name="Данные партнера"
            />
            <div className='ticket-page-selector'>
                {clientPages.map((page) => {
                    return (
                        <div
                            key={page.id}
                            className={'sub-page-name ' + (currentPageId === page.id ? "active" : "")}
                            onClick={() => setCurrenPageId(page.id)}
                        >
                            {page.name}
                        </div>
                    );
                })}
            </div>

            <SubPageComponent pageId={currentPageId} />
        </>
    );

}

export default PartnerPage;